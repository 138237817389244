/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 2; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */
#post-preview-img {
  margin: 0 !important;
}


figure {
  margin: 0 0 1rem 0;
}
